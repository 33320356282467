import React from 'react';

import './Presentkort.scss';

const Presentkort = () => {
    return (
        <div id="presentkort" className="app__presentkort">
            <h1>Presentkort & Tips inför besöket</h1>
            <div className="app__presentkort-container">
                <div>
                    <h2>Inför besöket</h2>
                    <div className="app__hr" style={{height: "4px", padding: 0, maxWidth: "20%"}} />
                    <ul>
                        <li>
                            <p>Ta gärna med rena ombytesstrumpor</p>
                        </li>
                        <li>
                            <p>Jag rekommenderar att avlägsna nagellack innan besöket. Om du har nagellack så tar vi bort det men detta kan korta ner behandlingstiden.</p>
                        </li>
                        <li>
                            <p>Avbokning görs 24 timmar innan bokad tid annars debiteras full avgift. Avbokning görs via mail eller telefon.</p>
                        </li>
                        <li>
                            <p>Hos mig kan man betala kontant, faktura eller via Swish.</p>
                        </li>
                    </ul>
                </div>
                <div>
                    <h2>Presentkort</h2>
                    <div className="app__hr-light" style={{height: "4px", padding: 0, maxWidth: "20%"}} />
                    <p>
                    Köp ett presentkort och ge bort en medicinsk fotvårdsbehandling till någon som behöver ge sina fötter den omsorg som de behöver.
                    <br /><br />
                    Hos mig kan du köpa presentkort för valfritt belopp. Presentkortet tas med till bokad behandling.<br />Presentkortet är giltigt i 12 månader från inköpsdatum.
                    <br />
                    Kontakta mig för mer information!
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Presentkort;