import React, { Component } from 'react';

import { FaUserAlt, FaAt } from 'react-icons/fa';
import { FcApproval } from 'react-icons/fc';
import { BsFillPhoneFill } from 'react-icons/bs';
import { HiMail } from 'react-icons/hi';

class Form extends Component {
    state = {
        name: '',
        phone: '',
        email: '',
        message: ''
    }

    handleChange = input => e => {
        this.setState({
            [input]: e.target.value
        });
    }


    submitForm = (e) => {
        e.preventDefault();
        this.setState({
            messageSending: true
        });

        if (this.state.name.length >= 2) {
            document.getElementById("contact-form_error").textContent="";
            document.getElementById("name").style.boxShadow="";

            let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (re.test(this.state.email)) {
                document.getElementById("contact-form_error").textContent="";
                document.getElementById("email").style.boxShadow="";

                if (this.state.message.length >= 10 && this.state.message.length <= 500) {
                    document.getElementById("contact-form_error").textContent="";
                    document.getElementById("message").style.boxShadow="";
                    const {
                        name,
                        phone,
                        email,
                        message
                    } = this.state;

                    var xhr = new XMLHttpRequest();
                    
                    xhr.addEventListener('load', () => {
                        //console.log(xhr.responseText);
                        
                        
                        this.setState({
                            name: '',
                            phone: '',
                            email: '',
                            message: '',
                            messageSend: true,
                            messageSending: false
                        });

                    });

                    xhr.open('GET', 'https://fothalsaniberg.se/api/index.php?&from=' + email +
                                '&name=' + name +
                                '&phone=' + phone +
                                '&message=' + message);

                    xhr.send();

                }else{
                    this.setState({
                        messageSending: false
                    });
                    document.getElementById("contact-form_error").textContent="Meddelandet måste innehålla minst 10 tecken";
                    document.getElementById("message").style.boxShadow="0 0 10px #2d7f83dc";
                    return;
                }

            }else{
                this.setState({
                    messageSending: false
                });
                document.getElementById("contact-form_error").textContent="Fyll i din E-Post Adress";
                document.getElementById("email").style.boxShadow="0 0 10px #2d7f83dc";
                return;
            }

        }else{
            this.setState({
                messageSending: false
            });
            document.getElementById("contact-form_error").textContent="Fyll i Förnamn & Efternamn";
            document.getElementById("name").style.boxShadow="0 0 10px #2d7f83dc";
            return;
        }
    }

    render(){
        const {
            name,
            phone,
            email,
            message,
            messageSend,
            messageSending
        } = this.state;
        
        return(
            <form 
                onSubmit={this.submitForm}
                className="app__kontakt-form"
            >
                <div className={messageSending ? 'spinner-div_contact-form active' : 'spinner-div_contact-form'}><div class="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div></div>
                <div className="app__kontakt-form_input">
                    <div className="app__kontakt-form_icon">
                        <FaUserAlt />
                    </div>
                    <input 
                        type="text" 
                        name="name"
                        id="name"
                        placeholder="Förnamn & Efternamn*" 
                        value={name}
                        onChange={this.handleChange('name')}
                    />
                </div>


                <div className="app__kontakt-form_input">
                    <div className="app__kontakt-form_icon">
                        <BsFillPhoneFill />
                    </div>
                    <input 
                        type="text" 
                        name="phone"
                        id="phone"
                        placeholder="Telefonnummer" 
                        value={phone}
                        onChange={this.handleChange('phone')}
                    />
                </div>


                <div className="app__kontakt-form_input">
                    <div className="app__kontakt-form_icon">
                        <FaAt />
                    </div>
                    <input 
                        type="text" 
                        name="email"
                        id="email"
                        placeholder="E-Post Adress*" 
                        value={email}
                        onChange={this.handleChange('email')}
                    />
                </div>


                <div className="app__kontakt-form_textarea">
                    <div className="app__kontakt-form_icon">
                        <HiMail />
                    </div>
                    <textarea 
                            type="text" 
                            name="message" 
                            id="message"
                            placeholder="Meddelande*"
                            value={message} 
                            onChange={this.handleChange('message')} 
                        ></textarea>
                </div>

                <div className="app__contact-form_button">
                {messageSend ? <h3 className={messageSend ? 'active' : null}><FcApproval />Tack för ditt meddelande, Jag återkopplar inom kort!</h3> : 
                <button type="submit" disabled={messageSending}>Skicka</button>
                }
                <p id="contact-form_error"></p>
                </div>
            </form>
        )
    }
}

export default Form;