import React from 'react';

import { 
    BehandlingarHeader, 
    MedicinskFotvard, 
    FotvardSomFriskvard, 
    FotSpa, 
    Tillagg, 
    SmaPaket,
    Presentkort
} from './Behandling';

const Behandlingar = () => {
    return (
        <div>
            <BehandlingarHeader />
            <MedicinskFotvard />
            <FotvardSomFriskvard />
            <FotSpa />
            <SmaPaket />
            <Tillagg />
            <Presentkort />
        </div>
    );
}

export default Behandlingar;