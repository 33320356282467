import React from 'react';
import { motion } from 'framer-motion';
import { HashLink } from 'react-router-hash-link';

import { GiFootprint, GiBarefoot, GiFootPlaster, GiWingfoot } from 'react-icons/gi';
import { FaArrowAltCircleDown, FaArrowAltCircleRight } from 'react-icons/fa';
import './BehandlingarHeader.scss';

const BehandlingarHeader = () => {
    return (
        <>
            <div id="behandlingar" className="app__behandlingar-header_bg">
                <div className="app__behandlingar-header">
                    <div className="app__behandlingar-header-container">
                    <motion.h1
                        whileInView={{ y: [10, 0], opacity: [0, 1] }}
                        transition={{ duration: 0.5 }}
                    >
                        Behandlingar <p>- Information & Priser</p>
                    </motion.h1>
                    <div className="app__behandlingar-header-content">
                        <div className="app__behandlingar-stora">

                            <HashLink smooth to="#medicinskfotvard" className="app__behandlingar-stor">
                                <div>
                                    <GiFootPlaster />
                                    <motion.h1
                                        whileInView={{ x: [15, 0], opacity: [0, 1] }}
                                        transition={{ duration: 0.5 }}
                                    >Medicinsk Fotvård</motion.h1>
                                    <motion.p
                                        whileInView={{ x: [15, 0], opacity: [0, 1] }}
                                        transition={{ duration: 0.5 }}
                                    >
                                        Medicinsk fotvård förebygger, behandlar och lindrar sjukdomar och andra ohälsotillstånd i foten och i underbenet.
                                    </motion.p>
                                    <motion.h4
                                        whileInView={{ x: [15, 0], opacity: [0, 1] }}
                                        transition={{ duration: 0.5, delay: 0.1 }}
                                    >
                                        Pris från: 600:- / 50min
                                    </motion.h4>
                                    <div className="app__behandlingar-stor-banner">
                                        Finns Med<br />
                                        Hembesök!
                                    </div>
                                    <div className="app__behandlingar-clickinfo">
                                        Läs mer <FaArrowAltCircleRight />
                                    </div>
                                </div>
                            </HashLink>

                            <HashLink smooth to="#fotvardsomfriskvard" className="app__behandlingar-stor">
                            <div>
                                <GiBarefoot />
                                <motion.h1
                                    whileInView={{ x: [15, 0], opacity: [0, 1] }}
                                    transition={{ duration: 0.5 }}
                                >Fotvård som friskvård</motion.h1>
                                <motion.p
                                    whileInView={{ x: [15, 0], opacity: [0, 1] }}
                                    transition={{ duration: 0.5 }}
                                >
                                    Behandlingen är momsbelagd och anpassad för dig som får friskvårdsbidrag av din arbetsgivare.
                                </motion.p>
                                <motion.h4
                                    whileInView={{ x: [15, 0], opacity: [0, 1] }}
                                    transition={{ duration: 0.5, delay: 0.1 }}
                                >
                                    Pris: 800:- / 60min
                                </motion.h4>

                                <div className="app__behandlingar-clickinfo">
                                        Läs mer <FaArrowAltCircleRight />
                                </div>
                            </div>
                            </HashLink>

                            <HashLink smooth to="#fotspa" className="app__behandlingar-stor">
                            <div>
                                <GiWingfoot />
                                <motion.h1
                                    whileInView={{ x: [15, 0], opacity: [0, 1] }}
                                    transition={{ duration: 0.5 }}
                                >fot-spa</motion.h1>
                                <motion.p
                                    whileInView={{ x: [15, 0], opacity: [0, 1] }}
                                    transition={{ duration: 0.5 }}
                                >
                                    Ta hand om dina fötter med fotskrubb, hälskrubb, nagelklippning och närande krämmassage, för komfort och avkoppling.
                                </motion.p>
                                <motion.h4
                                    whileInView={{ x: [15, 0], opacity: [0, 1] }}
                                    transition={{ duration: 0.5, delay: 0.1 }}
                                >
                                    Pris: 800:- / 60min
                                </motion.h4>
                                <motion.h4
                                    whileInView={{ x: [15, 0], opacity: [0, 1] }}
                                    transition={{ duration: 0.5, delay: 0.2 }}
                                >
                                    Pris: 1100:- / 75min
                                </motion.h4>

                                <div className="app__behandlingar-stor-banner">
                                    Välj Ekologiskt<br />& Veganskt!
                                </div>
                                <div className="app__behandlingar-clickinfo">
                                        Läs mer <FaArrowAltCircleRight />
                                </div>
                            </div>
                            </HashLink>
                        </div>

                        <HashLink smooth to="#smapaket"  className="app__behandlingar-smaa">
                                <div className="app__behandlingar-sma">
                                    <motion.h1
                                        whileInView={{ x: [15, 0], opacity: [0, 1] }}
                                        transition={{ duration: 0.5 }}
                                    >Fotvårtor</motion.h1>
                                    <motion.p
                                        whileInView={{ x: [15, 0], opacity: [0, 1] }}
                                        transition={{ duration: 0.5, delay: 0.1 }}
                                    >350:- för 30min</motion.p>
                                    <GiBarefoot />
                                </div>
                                <div className="app__behandlingar-sma">
                                    <motion.h1
                                        whileInView={{ x: [15, 0], opacity: [0, 1] }}
                                        transition={{ duration: 0.5 }}
                                    >Förhårdnader</motion.h1>
                                    <motion.p
                                        whileInView={{ x: [15, 0], opacity: [0, 1] }}
                                        transition={{ duration: 0.5, delay: 0.1 }}
                                    >350:- för 30min</motion.p>
                                    <GiFootprint />
                                </div>
                                <div className="app__behandlingar-sma">
                                    <motion.h1
                                        whileInView={{ x: [15, 0], opacity: [0, 1] }}
                                        transition={{ duration: 0.5 }}
                                    >Liktornar</motion.h1>
                                    <motion.p
                                        whileInView={{ x: [15, 0], opacity: [0, 1] }}
                                        transition={{ duration: 0.5, delay: 0.1 }}
                                    >350:- för 30min</motion.p>
                                    <GiBarefoot />
                                </div>
                                <div className="app__behandlingar-sma">
                                    <motion.h1
                                        whileInView={{ x: [15, 0], opacity: [0, 1] }}
                                        transition={{ duration: 0.5 }}
                                    >Nagelsvamp</motion.h1>
                                    <motion.p
                                        whileInView={{ x: [15, 0], opacity: [0, 1] }}
                                        transition={{ duration: 0.5, delay: 0.1 }}
                                    >350:- för 30min</motion.p>
                                    <GiFootprint />
                                </div>
                                <div className="app__behandlingar-sma">
                                    <motion.h1
                                        whileInView={{ x: [15, 0], opacity: [0, 1] }}
                                        transition={{ duration: 0.5 }}
                                    >Nageltrång</motion.h1>
                                    <motion.p
                                        whileInView={{ x: [15, 0], opacity: [0, 1] }}
                                        transition={{ duration: 0.5, delay: 0.1 }}
                                    >350:- för 30min</motion.p>
                                    <GiBarefoot />
                                </div>
                        </HashLink>

                        <HashLink smooth to="#tillagg" className="app__behandlingar-tillaggen">
                            <div className="app__behandlingar-tillagg">
                                <motion.h1
                                    whileInView={{ x: [15, 0], opacity: [0, 1] }}
                                    transition={{ duration: 0.5 }}
                                >Lackning</motion.h1>
                                <motion.p
                                    whileInView={{ x: [15, 0], opacity: [0, 1] }}
                                    transition={{ duration: 0.5, delay: 0.1 }}
                                >150:- för 15min</motion.p>
                                <GiFootprint />
                                <div className="app__behandlingar-tillagg_banner">
                                    Tilläggsbehandling!
                                </div>
                            </div>
                            <div className="app__behandlingar-tillagg">
                                <motion.h1
                                    whileInView={{ x: [15, 0], opacity: [0, 1] }}
                                    transition={{ duration: 0.5 }}
                                >Tejpning</motion.h1>
                                <motion.p
                                    whileInView={{ x: [15, 0], opacity: [0, 1] }}
                                    transition={{ duration: 0.5, delay: 0.1 }}
                                >150:- för 15min</motion.p>
                                <GiBarefoot />
                                <div className="app__behandlingar-tillagg_banner">
                                    Tilläggsbehandling!
                                </div>
                            </div>
                        </HashLink> 
                    </div>
                    </div>
                </div> 
            </div>
            <div className="app__behandlingsinfo-header">
                <FaArrowAltCircleDown />
                <h1>
                    Mer information om behandlingarna
                </h1>
                <div className="app__hr-light" />
            </div>
        </>
    );
}

export default BehandlingarHeader;