import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { HashLink } from 'react-router-hash-link';

import { images } from '../../constants';

import { AiFillCaretDown, AiFillCloseCircle } from 'react-icons/ai';
import { BsArrowRightShort } from 'react-icons/bs';
import { HiMenuAlt4, HiX } from 'react-icons/hi';

import './Navbar.scss';

function Navbar() {
    const [open, setOpen] = useState(false);
    const [toggle, setToggle] = useState(false);

    return (
        <motion.nav 
        animate={{ y: [-68, 0]}}
        className="app__navbar"
    >
        <div className="app__navbar-logo">
            <img src={images.logga} alt="Logga" />
        </div>
        <ul className="app__navbar-links">
            <li className="app__navbar-items">
                <HashLink smooth to="/#hem" className="app__navbar-link" onClick={() => setOpen(false)}>
                    Hem
                </HashLink>
                <div className="app__navbar-item-underline" />
            </li>
            <li className="app__navbar-items">
                <a onClick={() => setOpen(!open)} className="app__navbar-link" >
                    behandlingar <AiFillCaretDown size={10} />
                </a>
                <div className="app__navbar-item-underline" />
                <div className={open ? 'dropdown active' : 'dropdown'} >
                    <div className="dropdown-arrow" />
                    <div className="dropdown-container">
                        <HashLink smooth to="/behandlingar/#medicinskfotvard" className="app__navbar-item-dropdown" onClick={() => setOpen(false)}>
                            <span><BsArrowRightShort size={20} /></span>medicinsk fotvård
                        </HashLink>
                        <HashLink smooth to="/behandlingar/#fotvardsomfriskvard" className="app__navbar-item-dropdown" onClick={() => setOpen(false)}>
                            <span><BsArrowRightShort size={20} /></span>fotvård som friskvård
                        </HashLink>
                        <HashLink smooth to="/behandlingar/#fotspa" className="app__navbar-item-dropdown" onClick={() => setOpen(false)}>
                            <span><BsArrowRightShort size={20} /></span>fot-spa
                        </HashLink>
                        <HashLink smooth to="/behandlingar/#smapaket" className="app__navbar-item-dropdown" onClick={() => setOpen(false)}>
                            <span><BsArrowRightShort size={20} /></span>fotvårtor
                        </HashLink>
                        <HashLink smooth to="/behandlingar/#smapaket" className="app__navbar-item-dropdown" onClick={() => setOpen(false)}>
                            <span><BsArrowRightShort size={20} /></span>förhårdnader
                        </HashLink>
                        <HashLink smooth to="/behandlingar/#smapaket" className="app__navbar-item-dropdown" onClick={() => setOpen(false)}>
                            <span><BsArrowRightShort size={20} /></span>liktornar
                        </HashLink>
                        <HashLink smooth to="/behandlingar/#smapaket" className="app__navbar-item-dropdown" onClick={() => setOpen(false)}>
                            <span><BsArrowRightShort size={20} /></span>nagelsvamp
                        </HashLink>
                        <HashLink smooth to="/behandlingar/#smapaket" className="app__navbar-item-dropdown" onClick={() => setOpen(false)}>
                            <span><BsArrowRightShort size={20} /></span>nageltrång
                        </HashLink>
                        <HashLink smooth to="/behandlingar/#tillagg" className="app__navbar-item-dropdown" onClick={() => setOpen(false)}>
                            <span><BsArrowRightShort size={20} /></span>lackning
                        </HashLink>
                        <HashLink smooth to="/behandlingar/#tillagg" className="app__navbar-item-dropdown" onClick={() => setOpen(false)}>
                            <span><BsArrowRightShort size={20} /></span>tejpning
                        </HashLink>
                        <HashLink smooth to="/behandlingar/#behandlingar" className="app__navbar-item-dropdown" onClick={() => setOpen(false)}>
                            <span><BsArrowRightShort size={20} /></span>Se alla behandlingar*
                        </HashLink>
                        <HashLink smooth to="/behandlingar/#presentkort" className="app__navbar-item-dropdown" onClick={() => setOpen(false)}>
                            <span><BsArrowRightShort size={20} /></span>Presentkort & Inför besöket
                        </HashLink>
                    </div>
                    <div className="dropdown-close" onClick={() => setOpen(false)} >
                        <AiFillCloseCircle />
                    </div>
                </div>
            </li>
            <li className="app__navbar-items">
                <HashLink smooth to="/#ommig" className="app__navbar-link" onClick={() => setOpen(false)}>
                    om mig
                </HashLink>
                <div className="app__navbar-item-underline" />
            </li>
            <li className="app__navbar-items">
                <HashLink smooth to="/#kontakt" className="app__navbar-link" onClick={() => setOpen(false)}>
                    Kontakt
                </HashLink>
                <div className="app__navbar-item-underline" />
            </li>
        </ul>
        <div className="app__navbar-contact">
            Fothälsan I Berg
        </div>
        <div className="app__navbar-mobile">
            <HiMenuAlt4 onClick={() => setToggle(true)} />

            {toggle && 
                <div>
                    <ul>
                        <HiX onClick={() => setToggle(false)} />
                        {!open && (
                            <li>
                                <HashLink smooth to="/#hem" onClick={() => setToggle(false)}>Hem</HashLink>
                            </li>
                        )}
                        <li onClick={() => setOpen(!open)}>
                            <HashLink to="#">Behandlingar <AiFillCaretDown /></HashLink>
                            {open && 
                                <>
                                    <li>
                                        <HashLink smooth to="/behandlingar/#medicinskfotvard" onClick={() => setToggle(false)}>medicinsk fotvård</HashLink>
                                    </li>
                                    <li>
                                        <HashLink smooth to="/behandlingar/#fotvardsomfriskvard" onClick={() => setToggle(false)}>fotvård som friskvård</HashLink>
                                    </li>
                                    <li>
                                        <HashLink smooth to="/behandlingar/#fotspa" onClick={() => setToggle(false)}>fot-spa</HashLink>
                                    </li>
                                    <li>
                                        <HashLink smooth to="/behandlingar/#smapaket" onClick={() => setToggle(false)}>fotvårtor</HashLink>
                                    </li>
                                    <li>
                                        <HashLink smooth to="/behandlingar/#smapaket" onClick={() => setToggle(false)}>förhårdnader</HashLink>
                                    </li>
                                    <li>
                                        <HashLink smooth to="/behandlingar/#smapaket" onClick={() => setToggle(false)}>liktornar</HashLink>
                                    </li>
                                    <li>
                                        <HashLink smooth to="/behandlingar/#smapaket" onClick={() => setToggle(false)}>nagelsvamp</HashLink>
                                    </li>
                                    <li>
                                        <HashLink smooth to="/behandlingar/#smapaket" onClick={() => setToggle(false)}>nageltrång</HashLink>
                                    </li>
                                    <li>
                                        <HashLink smooth to="/behandlingar/#tillagg" onClick={() => setToggle(false)}>lackning</HashLink>
                                    </li>
                                    <li>
                                        <HashLink smooth to="/behandlingar/#tillagg" onClick={() => setToggle(false)}>tejpning</HashLink>
                                    </li>
                                    <li>
                                        <HashLink smooth to="/behandlingar/#behandlingar" onClick={() => setToggle(false)}>se alla behandlingar</HashLink>
                                    </li>
                                    <li>
                                        <HashLink smooth to="/behandlingar/#presentkort" onClick={() => setToggle(false)}>Presentkort & Inför besöket</HashLink>
                                    </li>
                                </>
                            }
                        </li>
                        {!open && (
                            <li>
                                <HashLink smooth to="/#ommig" onClick={() => setToggle(false)}>Om mig</HashLink>
                            </li>
                        )}
                        {!open && (
                            <li>
                                <HashLink smooth to="/#kontakt" onClick={() => setToggle(false)}>Kontakt</HashLink>
                            </li>
                        )}
                    </ul>
                </div>
            }
        </div>
    </motion.nav>
    );
}

export default Navbar;