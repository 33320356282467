import React, { useState, useEffect } from 'react';
import { collection, onSnapshot } from "firebase/firestore";
import { motion } from 'framer-motion';
import { db } from "../../firebase";

import { AppWrap } from '../../wrapper';
import Form from './Form';

import { FaAt } from 'react-icons/fa';
import { BsFillPhoneFill } from 'react-icons/bs';
import { HiOfficeBuilding } from 'react-icons/hi';
import './Kontakt.scss';

const Kontakt = () => {
    const [kontaktText, setKontaktText] = useState([]);

    useEffect(() => {
        const inlaggRef = collection(db, "Kontakt");
        onSnapshot(inlaggRef,(snapshot) => {
            const kontaktText = snapshot.docs.map((doc)=>({
                id: doc.id, 
                ...doc.data(),
            }));
            setKontaktText(kontaktText);
        });
    },[]);
    return (
        <div id="kontakt" className="app__kontakt">
            <motion.div className="app__kontakt-container">
                <motion.h1
                    whileInView={{ y: [-10, 0], opacity: [0, 1] }}
                    transition={{ duration: 0.5 }}
                >KONTAKT</motion.h1>
                <div className="app__hr" />
                <motion.div className="app__kontakt-form_container"
                    whileInView={{ y: [-20, 0], opacity: [0, 1] }}
                    transition={{ duration: 0.7 }}
                >
                    <div className="app__kontakt-form-text">
                        <h1>Skicka ett meddelande snabbt och smidigt genom kontaktformuläret!</h1>
                        <div className="app__hr-light" />
                    </div>
                    <Form />
                </motion.div>
                <motion.h2
                    whileInView={{ y: [-10, 0], opacity: [0, 1] }}
                    transition={{ duration: 0.5 }}
                >KONTAKT INFORMATION</motion.h2>
                <div className="app__hr" />


                {kontaktText && kontaktText.map(({id,phone,email,street,city,emailText,phoneText,phoneText2,openTime1,openTime2}) => 
                <div key={id} className="app__kontakt-ways">
                    <motion.div className="app__kontakt-email"
                        whileInView={{ y: [-20, 0], opacity: [0, 1] }}
                        transition={{ duration: 0.5 }}
                    >
                        <FaAt />
                        <h3>E-post Adress:</h3>
                        <p>{email}</p>
                        <br />
                        <p>{emailText}</p>
                    </motion.div>
                    <motion.div className="app__kontakt-phone"
                        whileInView={{ y: [-20, 0], opacity: [0, 1] }}
                        transition={{ duration: 0.6 }}
                    >
                        <BsFillPhoneFill />
                        <h3>Telefonnummer:</h3>
                        <p>{phone.replace('+46' , '0')}</p>
                        <br />
                        <p>{phoneText}</p>
                        <p>{phoneText2}</p>
                    </motion.div>
                    <motion.div className="app__kontakt-visit"
                        whileInView={{ y: [-20, 0], opacity: [0, 1] }}
                        transition={{ duration: 0.7 }}
                    >
                        <HiOfficeBuilding />
                        <h3>Adress:</h3>
                        <p>Fothälsan i Berg </p>
                        <p>{street}</p>
                        <p>{city}</p>
                        <br />
                        <p>{openTime1}</p>
                        <p className="app__kontakt-visit-red">{openTime2}</p>
                    </motion.div>
                </div>
                )}
            </motion.div>
        </div>
    );
}

export default AppWrap(Kontakt, 'app__kontakt');