import React, { useState, useEffect } from 'react';
import { collection, onSnapshot } from "firebase/firestore";
import { db } from '../../firebase';
import { motion } from 'framer-motion';
import images from '../../constants/images';
import { HashLink } from 'react-router-hash-link';

import { AppWrap } from '../../wrapper';

import { BsArrowRightShort } from 'react-icons/bs';
import './IndexText.scss';

const IndexText = () => {
    const [hemText, setHemText] = useState([]);

    useEffect(() => {
        const inlaggRef = collection(db, "Hem");
        onSnapshot(inlaggRef,(snapshot) => {
            const hemText = snapshot.docs.map((doc)=>({
                id: doc.id, 
                ...doc.data(),
            }));
            setHemText(hemText);
        });
    },[]);

    return (
        <div className="app__indextext">
            {hemText && hemText.map(({id,title,text,imageURL}) => 
            <motion.div key={id} className="app__indextext-container">
                <div className="app__indextext-textcontainer">
                    <motion.h1
                        whileInView={{ y: [-10, 0], opacity: [0, 1] }}
                        transition={{ duration: 0.5 }}
                    >
                        {title}
                    </motion.h1>
                    <div className="app__hr" />
                    <motion.p
                        whileInView={{ x: [-30, 0], opacity: [0, 1] }}
                        transition={{ duration: 0.7 }}
                        dangerouslySetInnerHTML={{__html:text}}
                    >
                    </motion.p>
                    <br />
                    <HashLink smooth to="/behandlingar/#behandlingar"><BsArrowRightShort /> Läs mer om behandlingar och priser här.</HashLink>
                </div>
                <motion.img 
                    src={imageURL} alt="hembild" 
                    whileInView={{ opacity: [0, 1] }}
                    transition={{ duration: 0.7 }}
                />
            </motion.div>
            )}
        </div>
    );
}

export default AppWrap(IndexText, 'app__indextext');