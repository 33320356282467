import React, { useState, useEffect } from 'react';
import { collection, onSnapshot } from "firebase/firestore";
import { db } from '../../firebase';
import { motion } from 'framer-motion';

import { AppWrap } from '../../wrapper';

import './Omoss.scss';

const Omoss = () => {
    const [omText, setOmText] = useState([]);

    useEffect(() => {
        const inlaggRef = collection(db, "OmMig");
        onSnapshot(inlaggRef,(snapshot) => {
            const omText = snapshot.docs.map((doc)=>({
                id: doc.id, 
                ...doc.data(),
            }));
            setOmText(omText);
        });
    },[]);

    return (
        <div id="ommig" className="app__omoss">
            <motion.div className="app__omoss-bg">
                <div className="app__omoss-container">
                    <motion.h1
                        whileInView={{ y: [-10, 0], opacity: [0, 1] }}
                        transition={{ duration: 0.5 }}
                    >OM MIG</motion.h1>
                    <div className="app__hr-light" />
                    {omText && omText.map(({id,title,text,imageURL,imageURL2,imageURL3}) =>
                    <div key={id} className="app__omoss-content"> 
                        <motion.div className="app__omoss-text"
                            whileInView={{ x: [-30, 0], opacity: [0, 1] }}
                            transition={{ duration: 0.7 }}
                        >
                            <h2>{title}</h2>
                            <p dangerouslySetInnerHTML={{__html:text}}></p>
                        </motion.div>
                        <motion.div className="app__omoss-img"
                            style={{background: `url(${imageURL})`, backgroundSize: 'cover', backgroundPosition: 'center'}}
                            whileInView={{ x: [30, 0], opacity: [0, 1] }}
                            transition={{ duration: 0.5 }}
                        >
                            <motion.div className="app__omoss-img2"
                                style={{background: `url(${imageURL2})`, backgroundSize: 'cover', backgroundPosition: 'center'}}
                                whileInView={{ scale: [0.5, 1], y: [-30, 0], opacity: [0, 1] }}
                                transition={{ duration: 0.7 }}
                            />
                            <motion.div className="app__omoss-img3"
                                style={{background: `url(${imageURL3})`, backgroundSize: 'cover', backgroundPosition: 'center'}}
                                whileInView={{ scale: [0.5, 1], y: [30, 0], opacity: [0, 1] }}
                                transition={{ duration: 0.7 }}
                            />
                        </motion.div>
                    </div>
                    )}
                </div>
            </motion.div>
        </div>
    );
}

export default AppWrap(Omoss, 'app__omoss');