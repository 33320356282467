import React, { useState, useEffect } from 'react';
import { collection, onSnapshot } from "firebase/firestore";
import { db } from "../../../../firebase";
import { motion } from 'framer-motion';

import { AppWrap } from '../../../../wrapper';

import './SmaPaket.scss';


const smapaket = [
    { title: 'Fotvårtor', price: '350:- för 30min' },
    { title: 'Förhårdnader', price: '350:- för 30min' },
    { title: 'Liktornar', price: '350:- för 30min' },
    { title: 'Nagelsvamp', price: '350:- för 30min' },
    { title: 'Nageltrång', price: '350:- för 30min' }
];

const SmaPaket = () => {
    const [kontaktText, setKontaktText] = useState([]);

    useEffect(() => {
        const inlaggRef = collection(db, "Kontakt");
        onSnapshot(inlaggRef,(snapshot) => {
            const kontaktText = snapshot.docs.map((doc)=>({
                id: doc.id, 
                ...doc.data(),
            }));
            setKontaktText(kontaktText);
        });
    },[]);

    return (
        <div id="smapaket" className="app__smapaket">
            <motion.div className="app__smapaket-container">
                <h1>Delbehandling</h1>
                <div className="app__smapaket-content">
                    {smapaket.map((smapaket, index) => (
                        <div
                            key={index}
                            className="app__smapaket-item"
                        >
                            <h1>{smapaket.title}</h1>
                            <p>{smapaket.price}</p>
                            {kontaktText && kontaktText.map(({id,email,phone}) => 
                            <div key={id}>
                                <a href={`tel:${phone}`}>{phone}</a>
                                <p>BOKA TID</p>
                                <a href={`mailto:${email}`}>{email}</a>
                            </div>
                            )}
                        </div>
                    ))}
                </div>
            </motion.div>
        </div>
    );
}

export default AppWrap(SmaPaket, 'app__smapaket');