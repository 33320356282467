import logga from '../assets/logo/fothalsaniberg-loggamedtext_739x114.png';
import loggab from '../assets/logo/fothalsaniberg-loggastor_1080x560.png';
import loggac from '../assets/logo/fothalsaniberg-logga_560x560.png';

import indexLoggaText from '../assets/logo/logga_text.png';
import indexLoggaSlogan from '../assets/logo/logga_slogan.png';
import IndexLoggaLoggan from '../assets/logo/logga_loggan470.png';

import loggaGoogleMap from '../assets/logo/fothalsaniberg-googlemap-icon.png';

import profilbild from '../assets/personal/IMG_4315.JPG';

import fotter from '../assets/feets/fotter.jpeg';
import fotter2 from '../assets/feets/fotter2.jpg';
import fotter3 from '../assets/feets/fotter3.jpg';
import fotter4 from '../assets/feets/pexels-photo-9486780.jpeg';
import fotter5 from '../assets/feets/pexels-photo-6628700.jpeg';
import fotter6 from '../assets/feets/pexels-photo-5240642.jpeg';

import fotterIndex1 from '../assets/feets/IMG_4285.jpg';

export default {
    logga,
    loggab,
    loggac,
    loggaGoogleMap,
    fotter,
    fotter2,
    fotter3,
    fotter4,
    fotter5,
    fotter6,
    fotterIndex1,
    profilbild,
    indexLoggaText,
    indexLoggaSlogan,
    IndexLoggaLoggan
}