import React from 'react';
import { motion } from 'framer-motion';

import { AppWrap } from '../../../../wrapper';

import './Tillagg.scss';

const Tillagg = () => {
    return (
        <motion.div id="tillagg" className="app__tillag">
            <h1>Tilläggsbehandling</h1>
            <div className="app__tillag-container">
                <div>
                    <h1>Lackning</h1>
                    <div className="app__hr" style={{height: "4px", padding: 0, maxWidth: "20%"}} />
                    <p>
                        Om du önskar att jag lackar dina tånaglar efter fotvården så lägger du till denna behandling. 
                        Du väljer självklart nagellack.
                    </p>
                    <p>
                        <span>Pris: 150:- / 15 minuter</span>
                    </p>
                </div>
                <div>
                    <h1>Tejpning</h1>
                    <div className="app__hr" style={{height: "4px", padding: 0, maxWidth: "20%"}} />
                    <p>
                        Tejpning av t.ex. hallux valgus, hälsporre & hammartå.
                    </p>
                    <p>
                        <span>Pris: 150:- / 15 minuter</span>
                    </p>
                </div>
            </div>
        </motion.div>
    );
}

export default AppWrap(Tillagg, 'app__tillag');