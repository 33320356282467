import React, { useState, useEffect } from 'react';
import { collection, onSnapshot } from "firebase/firestore";
import { db } from "../../../../firebase";
import { motion } from 'framer-motion';

import { AppWrap } from '../../../../wrapper';

import { images } from '../../../../constants';
import './MedicinskFotvard.scss';

const MedicinskFotvard = () => {
    const [clickedt, setClickedt] = useState(false)
    const [clickede, setClickede] = useState(false)
    const [kontaktText, setKontaktText] = useState([]);

    useEffect(() => {
        const inlaggRef = collection(db, "Kontakt");
        onSnapshot(inlaggRef,(snapshot) => {
            const kontaktText = snapshot.docs.map((doc)=>({
                id: doc.id, 
                ...doc.data(),
            }));
            setKontaktText(kontaktText);
        });
    },[]);

    return (
        <div id="medicinskfotvard" className="app__medfot">
            <motion.div className="app__medfot-container">
                <h1>MEDICINSK FOTVÅRD</h1>
                <div className="app__medfot-top">
                    <div className="app__medfot-img">
                        <img src={images.fotter4} alt="feets" />
                    </div>
                    <div className="app__medfot-bok">
                        <h1>Tidsbokning för Medicinsk Fotvård</h1>
                        <div className="app__hr" />
                        <p>
                            <p>
                                Olika varianter:
                            </p>
                            <p>
                                - Medicinsk Fotvård
                            </p>
                            <p>
                                - Medicinsk Fotvård Hembesök
                            </p>
                            <p>
                                - Medicinsk Fotvård XL
                            </p>
                        </p>
                        {kontaktText && kontaktText.map(({id,city,street,phone,email}) =>
                        <span key={id}>
                            <p>
                                Du kan boka tid via telefon eller email. <br />
                                Välkommen in till oss på {street} - {city}.
                            </p>
                            <a href={`tel:${phone}`} onClick={() => setClickedt(!clickedt)}><div>{clickedt ? `RINGER: ${phone.replace('+46' , '0')}` : 'RING'}</div></a>
                            <a href={`mailto:${email}`} onClick={() => setClickede(!clickede)}><div>{clickede ? `SKICKA TILL: ${email}` : 'SKICKA E-POST'}</div></a>
                        </span>
                        )}
                        <h4>
                            Tips inför ditt besök
                            <ul>
                                <li>
                                    Ta gärna med rena ombytesstrumpor
                                </li>
                                <li>
                                    Jag rekommenderar att avlägsna nagellack innan besöket. Om du har nagellack så tar vi bort det men detta kan korta ner behandlingstiden.
                                </li>
                                <li>
                                    Avbokning görs 24 timmar innan bokad tid annars debiteras full avgift. Avbokning görs via mail eller telefon.
                                </li>
                                <li>
                                    Hos mig kan man betala kontant, faktura eller via Swish.
                                </li>
                            </ul>
                        </h4>
                    </div>
                    <div className="app__medfot-arrow" />
                </div>
                <div className="app__medfot-content">
                    <h1>Medicinsk Fotvård</h1>
                    <div className="app__hr" />
                    <p>
                        Medicinsk fotvårdsbehandling börjar med undersökning av naglar och fötter därefter ett mjukgörande fotbad. 
                        Sedan klipps, rensas och slipas naglarna.<br /> Vid behov behandlas även andra åkommor som nageltrång, hälsprickor, förhårdnader och liktornar. 
                        <br />Behandlingen avslutas med fotmassage med mjukgörande fotkräm.
                    </p>
                    <p>
                        <span>Tid: 50 minuter</span>
                        <span>Pris: 600:-</span>
                    </p>
                    <h2>Medicinsk Fotvård - Hembesök</h2>
                    <div className="app__hr" />
                    <p>
                        Jag erbjuder hembesök inom Bergs kommun för personer som inte kan ta sig till min klinik på egen hand. 
                        Hembesöken görs på dag/kvällstid.<br />
                        Kontakta mig för mer information!
                    </p>
                    <p>
                        <span>Tid: 50 minuter</span>
                        <span>Pris: 700:-</span>
                    </p>
                    <h2>Medicinsk Fotvård - XL</h2>
                    <div className="app__hr" />
                    <p>
                        För dig med större behov kan jag rekommendera en längre behandling. Jag ser över dina naglar samt fötter, behandlar efter behov.<br />
                        Behandlingen avslutas med fotmassage, med mjukgörande fotkräm anpassad efter dina behov. Vi diskuterar även egenvård.
                    </p>
                    <p>
                        <span>Tid: 75 minuter</span>
                        <span>Pris: 850:-</span>
                    </p>
                </div>
            </motion.div>
        </div>
    );
}

export default AppWrap(MedicinskFotvard, 'app__medfot');