import React, { useState, useEffect } from 'react';
import { collection, onSnapshot, orderBy, query } from "firebase/firestore";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { db } from "./firebase";

import { Footer, Behandlingar } from './container';
import { Navbar } from './components';
import Main from './routes';
import './App.scss';

const App = () => {
    const [kontaktText, setKontaktText] = useState([]);
    const [partners, setPartners] = useState([]);

    useEffect(() => {
        const articleRef = collection(db, "Partners");
        const q = query(articleRef, orderBy("createdAt", "desc"));
        onSnapshot(q, (snapshot) => {
        const partners = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
        }));
        setPartners(partners);
        });
    }, []);

    useEffect(() => {
        const inlaggRef = collection(db, "Kontakt");
        onSnapshot(inlaggRef,(snapshot) => {
            const kontaktText = snapshot.docs.map((doc)=>({
                id: doc.id, 
                ...doc.data(),
            }));
            setKontaktText(kontaktText);
        });
    },[]);

    return (
        <div className="app">
            <Router>
                <Navbar />
                    <Routes>
                    <Route path="/" index element={<Main />} />
                    <Route path="/behandlingar" element={<Behandlingar />} />
                    </Routes>
                <Footer kontaktText={kontaktText} partners={partners} />
            </Router>
        </div>
    );
}

export default App;