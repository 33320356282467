import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, EffectFade, Zoom } from "swiper";

import { images } from '../../constants';

import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/zoom";

import './BildSlider.scss';


const BildSlider = () => {
    return (
        <>
            <Swiper
                spaceBetween={0}
                centeredSlides={true}
                effect={"fade"}
                loop={true}
                zoom={true}
                speed={5000}
                allowTouchMove={false}
                autoplay={{
                    delay: 7000,
                    disableOnInteraction: false,
                }}
                modules={[Autoplay, EffectFade, Zoom]}
                className="mySwiper"
            >
                <SwiperSlide className="app__swiper-image-container">
                    <img src={images.fotter} alt="fotter" className="app__swiper-image" />
                </SwiperSlide>
                <SwiperSlide className="app__swiper-image-container">
                    <img src={images.fotter2} alt="fotter2" className="app__swiper-image" />
                </SwiperSlide>
                <SwiperSlide className="app__swiper-image-container">
                    <img src={images.fotter3} alt="fotter3" className="app__swiper-image" />
                </SwiperSlide>
            </Swiper>
        </>
    );
}

export default BildSlider;