import React from 'react';

import { Header, Omoss, Kontakt } from '../container';
import { IndexText } from '../components';

const index = () => {
    return (
        <>
            <Header />
            <IndexText />
            <Omoss />
            <Kontakt />
        </>
    );
};

export default index;