import React from 'react';
import { 
    GoogleMap,
    useLoadScript,
    Marker, 
    InfoWindow
} from '@react-google-maps/api';

import { images } from '../../constants';
import mapStyles from './mapStyles';

import './Map.scss';

const libraries = ["places"];
const mapContainerStyle = {
    width: "100%",
    height: "300px",
}
const center = {
    lat: 59.329323,
    lng: 18.068581,
}
const options = {
    styles: mapStyles,
    disableDefaultUI: true,
    zoomControl: true,
}

function Map() {
    const {isLoaded, loadError} = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        libraries,
    });

    if(loadError) return "Kunde inte ladda kartan";
    if(!isLoaded) return "Laddar Karta...";

    return (
        <div className="app__google-map">
            <span>
                <img src={images.loggac} alt="logga" />
            </span>
            <GoogleMap 
                mapContainerStyle={mapContainerStyle}
                zoom={16}
                center={center}
                options={options}
            >
                <Marker 
                    position={center} 
                    icon={{
                        url: images.loggaGoogleMap,
                        scaledSize: new window.google.maps.Size(30, 43),
                    }}
                />
            </GoogleMap>
        </div>
    );
}

export default Map;