import React, { useState, useEffect } from 'react';
import { collection, onSnapshot } from "firebase/firestore";
import { db } from "../../../../firebase";
import { motion } from 'framer-motion';

import { AppWrap } from '../../../../wrapper';

import { images } from '../../../../constants';
import './FotSpa.scss';

const FotSpa = () => {
    const [clickedt, setClickedt] = useState(false)
    const [clickede, setClickede] = useState(false)
    const [kontaktText, setKontaktText] = useState([]);

    useEffect(() => {
        const inlaggRef = collection(db, "Kontakt");
        onSnapshot(inlaggRef,(snapshot) => {
            const kontaktText = snapshot.docs.map((doc)=>({
                id: doc.id, 
                ...doc.data(),
            }));
            setKontaktText(kontaktText);
        });
    },[]);

    return (
        <div id="fotspa" className="app__fotspa">
            <motion.div className="app__fotspa-container">
                <h1>FOT-SPA</h1>
                <div className="app__fotspa-top">
                    <div className="app__fotspa-img">
                        <img src={images.fotter6} alt="feets" />
                    </div>
                    <div className="app__fotspa-bok">
                        <h1>Tidsbokning för Fot-SPA</h1>
                        <div className="app__hr" />
                        <p>
                            <p>
                                Olika varianter:
                            </p>
                            <p>
                                - Ekologisk/Vegansk fot-SPA
                            </p>
                            <p>
                                - DeLux fot-SPA
                            </p>
                        </p>
                        {kontaktText && kontaktText.map(({id,city,street,phone,email}) =>
                        <span key={id}>
                            <p>
                                Du kan boka tid via telefon eller email. <br />
                                Välkommen in till oss på {street} - {city}.
                            </p>
                            <a href={`tel:${phone}`} onClick={() => setClickedt(!clickedt)}><div>{clickedt ? `RINGER: ${phone.replace('+46' , '0')}` : 'RING'}</div></a>
                            <a href={`mailto:${email}`} onClick={() => setClickede(!clickede)}><div>{clickede ? `SKICKA TILL: ${email}` : 'SKICKA E-POST'}</div></a>
                        </span>
                        )}
                        <h4>
                            Tips inför ditt besök
                            <ul>
                                <li>
                                    Ta gärna med rena ombytesstrumpor
                                </li>
                                <li>
                                    Jag rekommenderar att avlägsna nagellack innan besöket. Om du har nagellack så tar vi bort det men detta kan korta ner behandlingstiden.
                                </li>
                                <li>
                                    Avbokning görs 24 timmar innan bokad tid annars debiteras full avgift. Avbokning görs via mail eller telefon.
                                </li>
                                <li>
                                    Hos mig kan man betala kontant, faktura eller via Swish.
                                </li>
                            </ul>
                        </h4>
                    </div>
                    <div className="app__fotspa-arrow" />
                </div>
                <div className="app__fotspa-content">
                    <h1>Ekologisk/Vegansk fot-SPA</h1>
                    <div className="app__hr" />
                    <p>
                        Denna behandling piggar upp trötta fötter.<br />
                        Behandlingen börjar med ett uppiggande fotbad som lindrar muskelspänningar och verkar "renande". 
                        Ett fotbad och fotskrubb som utförs med ekologiska/veganska och näringsrika produkter. Därefter gör jag en enklare 
                        fotvårdsbehandling och avslutar med en avkopplande fotmassage.
                    </p>
                    <p>
                        <span>Tid: 60 minuter</span>
                        <span>Pris: 800:-</span>
                    </p>
                    <h1>DeLux fot-SPA</h1>
                    <div className="app__hr" />
                    <p>
                        Lyxig "må bra-behandling"<br />
                        Fotvård kombineras med fotbad/skrubb och skalpmassage.<br />
                        Fotbad och fotskrubb utförs med ekologiska produkter och ger dina fötter en riktig energiboost.
                        Därefter görs en enklarefotvårdsbehandling och avslutar med en avkopplande fot- & underbensmassage.
                    </p>
                    <p>
                        <span>Tid: 75 minuter</span>
                        <span>Pris: 1100:-</span>
                    </p>
                </div>
            </motion.div>
        </div>
    );
}

export default AppWrap(FotSpa, 'app__fotspa');