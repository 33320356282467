import React, { useState, useEffect } from 'react';
import { collection, onSnapshot } from "firebase/firestore";
import { db } from "../../../../firebase";
import { motion } from 'framer-motion';

import { AppWrap } from '../../../../wrapper';

import { images } from '../../../../constants';
import './FotvardSomFriskvard.scss';

const FotvardSomFriskvard = () => {
    const [clickedt, setClickedt] = useState(false)
    const [clickede, setClickede] = useState(false)
    const [kontaktText, setKontaktText] = useState([]);

    useEffect(() => {
        const inlaggRef = collection(db, "Kontakt");
        onSnapshot(inlaggRef,(snapshot) => {
            const kontaktText = snapshot.docs.map((doc)=>({
                id: doc.id, 
                ...doc.data(),
            }));
            setKontaktText(kontaktText);
        });
    },[]);

    return (
        <div id="fotvardsomfriskvard" className="app__fsf">
            <motion.div className="app__fsf-container">
                <h1>FOTVÅRD SOM FRISKVÅRD</h1>
                <div className="app__fsf-top">
                    <div className="app__fsf-img">
                        <img src={images.fotter5} alt="feets" />
                    </div>
                    <div className="app__fsf-bok">
                        <h1>Tidsbokning för Friskvårdspaketet</h1>
                        <div className="app__hr" />
                        {kontaktText && kontaktText.map(({id,city,street,phone,email}) =>
                        <span key={id}>
                            <p>
                                Du kan boka tid via telefon eller email. <br />
                                Välkommen in till oss på {street} - {city}.
                            </p>
                            <a href={`tel:${phone}`} onClick={() => setClickedt(!clickedt)}><div>{clickedt ? `RINGER: ${phone.replace('+46' , '0')}` : 'RING'}</div></a>
                            <a href={`mailto:${email}`} onClick={() => setClickede(!clickede)}><div>{clickede ? `SKICKA TILL: ${email}` : 'SKICKA E-POST'}</div></a>
                        </span>
                        )}
                        <h4>
                            Tips inför ditt besök
                            <ul>
                                <li>
                                    Ta gärna med rena ombytesstrumpor
                                </li>
                                <li>
                                    Jag rekommenderar att avlägsna nagellack innan besöket. Om du har nagellack så tar vi bort det men detta kan korta ner behandlingstiden.
                                </li>
                                <li>
                                    Avbokning görs 24 timmar innan bokad tid annars debiteras full avgift. Avbokning görs via mail eller telefon.
                                </li>
                                <li>
                                    Hos mig kan man betala kontant, faktura eller via Swish.
                                </li>
                            </ul>
                        </h4>
                    </div>
                    <div className="app__fsf-arrow" />
                </div>
                <div className="app__fsf-content">
                    <h1>Fotvård Som Friskvård</h1>
                    <div className="app__hr" />
                    <p>
                        Fotvård som friskvård börjar med ett härligt mjukgörande fotbad.<br />
                        Naglarna klipps, rensas och slipas. Sessionen innehålleräven delar där vi diskuterar hur du kan förebygga fotproblem.<br /><br />
                        Som avslutning filar jag dina fötter och behandlingen avslutas med en skön avkopplande fotmassage med mjukgörande fotkräm.
                    </p>
                    <p>
                        <span>Tid: 60 minuter</span>
                        <span>Pris: 800:-</span>
                    </p>
                    <i>
                        "Om din arbetsplats har friskvård som personalförmån då kan du använda det till fotvård för att förebygga fotproblem. 
                        Mitt friskvårdspaket består av en helbehandling på 60 minuter och denna behandling är momsbelagd. 
                        Här betalar du för din fotvård och tar med dig kvittot till din arbetsgivare som sedan ersätter dig för utlägget."
                    </i>
                </div>
            </motion.div>
        </div>
    );
}

export default AppWrap(FotvardSomFriskvard, 'app__fsf');