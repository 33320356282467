import React from 'react';
import { motion } from 'framer-motion';

import { BildSlider } from '../../components';
import './Header.scss';
import { images } from '../../constants';



const Header = () => {
    return (
        <div id="hem" className="app__header">
            <div className="swiper-wrapper app__header-container">
                <motion.div 
                    className="app__header-img"
                >
                    <motion.img src={images.IndexLoggaLoggan} alt="logga" 
                        animate={{ y: [50, 0], opacity: [0, 1] }}
                        transition={{ duration: 0.5, ease: 'easeInOut', delay: 0.9 }}
                    />
                    <motion.div 
                        animate={{ x: [-200, 0], opacity: [0, 1] }}
                        transition={{ duration: 0.3, ease: 'easeInOut' }}
                    />
                    <motion.img src={images.indexLoggaText} alt="logga" 
                        animate={{ scale: [0.5, 1], opacity: [0, 1] }}
                        transition={{ duration: 0.5, ease: 'easeInOut', delay: 0.3 }}
                    />
                    <motion.div 
                        animate={{ x: [200, 0], opacity: [0, 1] }}
                        transition={{ duration: 0.3, ease: 'easeInOut' }}
                    />
                    <motion.img src={images.indexLoggaSlogan} alt="logga" 
                        animate={{ y: [-50, 0], opacity: [0, 1] }}
                        transition={{ duration: 0.3, ease: 'easeInOut', delay: 1 }}
                    />
                </motion.div>
            </div>
            <BildSlider />
        </div>
    );
}

export default Header;