import React from 'react';
import { motion } from 'framer-motion';
import { HashLink } from 'react-router-hash-link';

import { SocialIcon } from 'react-social-icons';
import { images } from '../../constants';
import './Footer.scss';

const Footer = ({ kontaktText, partners }) => {
    
    return (
        <div className="app__footer">
            <motion.h1
                whileInView={{ y: [-10, 0], opacity: [0, 1] }}
                transition={{ duration: 0.8 }}
            >
                Foten är viktig
            </motion.h1>
            <div className="app__footer-hr" />
            <div className="app__footer-container">
                <motion.div className="app__footer-links"
                    whileInView={{ x: [-50, 0], opacity: [0, 1] }}
                    transition={{ duration: 1 }}
                >
                    <div className="app__footer-link">
                        <HashLink smooth to="/#hem">Hem</HashLink>
                        <HashLink smooth to="/behandlingar/#behandlingar">Behandlingar</HashLink>
                        <HashLink smooth to="/#ommig">Om Mig</HashLink>
                        <HashLink smooth to="/#kontakt">Kontakt</HashLink>
                    </div>
                    <div className="app__footer-dropdownlink">
                        <div className="app__footer-arrow" />
                        <HashLink smooth to="/behandlingar/#medicinskfotvard">Medicinsk Fotvård</HashLink>
                        <HashLink smooth to="/behandlingar/#fotvardsomfriskvard">Fotvår Som Friskvård</HashLink>
                        <HashLink smooth to="/behandlingar/#fotspa">Fot-Spa</HashLink>
                        <HashLink smooth to="/behandlingar/#smapaket">Fotvårtor</HashLink>
                        <HashLink smooth to="/behandlingar/#smapaket">Förhårdnader</HashLink>
                        <HashLink smooth to="/behandlingar/#smapaket">Liktornar</HashLink>
                        <HashLink smooth to="/behandlingar/#smapaket">Nagelsvamp</HashLink>
                        <HashLink smooth to="/behandlingar/#smapaket">Nageltrång</HashLink>
                        <HashLink smooth to="/behandlingar/#tillagg">Lackning</HashLink>
                        <HashLink smooth to="/behandlingar/#tillagg">Tejpning</HashLink>
                        <HashLink smooth to="/behandlingar/#presentkort">Presentkort & Inför Besöket</HashLink>
                        
                    </div>
                </motion.div>
                <motion.div className="app__footer-sponsor"
                    whileInView={{ y: [-50, 0], opacity: [0, 1] }}
                    transition={{ duration: 1 }}
                >
                    <h1>Partners</h1>
                    <div>
                        {partners && partners.map(
                            ({ id, title, link, imageURL }) => (
                        
                            <a  key={id} href={link} target="_blank">
                                <img src={imageURL} alt={title} />
                            </a>
                        
                        ))}
                    </div>
                </motion.div>
                <motion.div className="app__footer-content"
                    whileInView={{ x: [50, 0], opacity: [0, 1] }}
                    transition={{ duration: 1 }}
                >
                    <div className="app__footer-logo">
                        <img src={images.loggac} alt="logga" />
                    </div>
                    {kontaktText && kontaktText.map(({id,phone,email,street,city}) => 
                    <div key={id} className="app__footer-contactinfo">
                        <h1>Kontakt info</h1>
                        <h2>Besöksadress</h2>
                        <p>{street}</p>
                        <p>{city}</p>
                        <h2>E-post Adress</h2>
                        <p>{email}</p>
                        <h2>Telefonnummer</h2>
                        <p>{phone.replace('+46' , '0')}</p>
                    </div>
                    )} 
                </motion.div>
            </div>
            <div className="app__footer-social" >
                <a href="https://www.facebook.com/Fothälsan-i-Berg-107140322061043/" target="_blank">
                    <motion.div
                        whileInView={{ y: [20, 0], opacity: [0, 1] }}
                        transition={{ duration: 0.5 }}
                    >
                        <SocialIcon network="facebook" fgColor="#ffffff" />
                    </motion.div>
                </a>
            </div>
            <div className="app__footer-copyright">
                &copy;Copyright Fothälsan I Berg 2022
            </div>
        </div>
    );
}

export default Footer;